import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import BlogSinglePost from "../components/Blog/BlogSinglePost";

const Post = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Special Effects Then and Now | Painted Rhino "
            description="Did you know that special effects have been around since the late 1850s? Here's a look at how they’ve evolved since then."
            // openGraphImage={data.openGraphImage.publicURL}
            // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <BlogSinglePost
            title="Special Effects Then and Now"
            date="August 16, 2021"
            author="Painted Rhino"
            category=""
            featuredImage={data.featuredImage.childImageSharp.fluid}
            content={
               <>
                  <p>
                     While it might not seem like it, special effects have been around since the late 1850s, with the first motion picture effect
                     developed in{" "}
                     <a href="https://www.pbs.org/wgbh/nova/specialfx2/1890.html" target="_blank" rel="noreferrer">
                        1895 by Alfred Clark
                     </a>
                     . He used a practical dummy in place of an actor during an execution scene. Multiple exposures, time-lapse photography,
                     hand-painted frames, and dissolves wouldn't come into cinematography until 1914.
                  </p>

                  <p>
                     While many people are used to seeing effects in cinema, a special effect can be used on and off-screen today. Additionally, in
                     many instances, there is no reason for practical effects to achieve certain shots. With the changes in technology and techniques,
                     cinematographers and entertainers can develop worlds and have viewers dispel disbelief, if only for a moment. However, before you
                     can understand the evolutions in the industry, it’s necessary to develop a working definition of effects.
                  </p>

                  <h2>Difference Between Special Effects and Visual Effects</h2>

                  <p>
                     When discussing effects in the entertainment industry, you’ll often hear two phrases used seemingly interchangeably:{" "}
                     <a href="https://www.ciit.edu.ph/special-effects/" target="_blank" rel="noreferrer">
                        special effects and visual effects
                     </a>
                     . While some might use these terms as synonyms, they are not. 
                  </p>

                  <p>
                     Visual effects are a reference to computer-aided and after-effects. In other words, the effects do not rely on mechanical or
                     practical effects; everything can be added in after a shoot.
                  </p>

                  <p>
                     Special effects refer to the more traditional methods and techniques for creating things like 'stop tricks.' For these effects to
                     work, they require mechanical and practical applications. They must become part of the filmmaking process and be present with the
                     actors.
                  </p>

                  <h2>Computer Effects vs. Practical Effects</h2>

                  <p>
                     During the 1990s,{" "}
                     <a
                        href="https://www.nyfa.edu/student-resources/cgi-animation-history-defining-and-awesome-moments-in-cinema/"
                        target="_blank"
                        rel="noreferrer"
                     >
                        computer-generated imagery
                     </a>{" "}
                     became an essential part of filming and production. The tools allowed directors, producers, and actors to maintain safer sets
                     with minimal risks of injuries.
                  </p>

                  <p>
                     While practical effects can help ground a scene, it’s easy to see how someone might end up hurt when you get into pyrotechnics
                     and other techniques. However, for theming and environment building, practical and mechanical effects can’t be beaten. The
                     tangible nature of practical effects is more rewarding for the visitor, viewer, and actor.
                  </p>

                  <p>
                     Despite the potential cost-savings with computer animation, CGI is challenged to sell reality in a 3D environment. Therefore,
                     most special effect artists agree that there will always be a place for practical and mechanical effects.
                  </p>

                  <h2>Special Effects and Prop Services</h2>

                  <p>
                     Whether you’re a filmmaker or a venue manager, there are countless reasons and advantages to using special effects and theming
                     for your project. The ability to interact with the mechanical and practical effects will help your actors or customers connect to
                     the underlying theme. Anything you dream of is possible with special effects.
                  </p>

                  <h2>Have a Project in Mind?</h2>

                  <p>
                     Painted Rhino provides props and special effects solutions for filmmakers, film production companies, theater, and more. With our
                     in-house capabilities and creative build team, we can fabricate your props, backgrounds, and displays of all sizes. From creature
                     suits to custom scenery, we help you tell your story effectively.
                  </p>

                  <p>
                     <a href="/special-effects-props/">Contact us</a> to discuss your project's goal and your ambition. Then, watch as your
                     imagination springs to life.
                  </p>
               </>
            }
         />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/FB_Global.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Twitter_Global.jpg" }) {
         publicURL
      }
      featuredImage: file(relativePath: { eq: "blog/081121_special_effects.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 833) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
   }
`;

export default Post;
